import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import Constants from "../../constants";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { setListPathName } from "store/slice/titleRoute.slice";
import { ModuleProductTemplate, TemplateType } from "@maysoft/sale-common-react";

const ProductBlueprintScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const idDetail: string = searchParams.get("id");

    const modeList = Screens.PRODUCT_BLUEPRINT_LIST.includes(window.location.pathname);

    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);
    const itemPathName = listPathName.find((el) => el.pathName === Screens.PRODUCT_BLUEPRINT_LIST);

    const [mode, setMode] = useState<Mode>((location?.state as any)?.mode || Mode.Create);
    const [requestData] = useState<any>({
        searchText: searchParams.get("searchText"),
        totalCount: itemPathName?.totalCount || 0,
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    useEffect(() => {
        if (Helpers.isNullOrEmpty(idDetail)) {
            setMode(Mode.Create);
        } else {
            setMode((location?.state as any)?.mode || Mode.View);
        }
    }, [idDetail]);

    const title = useMemo(() => {
        if (Helpers.isNullOrEmpty(idDetail)) return Strings.PRODUCT_BLUEPRINT.TITLE_CREATE_VIEW;
        return mode === Mode.View ? Strings.PRODUCT_BLUEPRINT.TITLE_DETAIL_VIEW : Strings.PRODUCT_BLUEPRINT.TITLE_UPDATE_VIEW;
    }, [idDetail, mode]);

    const route = useMemo(() => {
        const temp = [{ title: Strings.PRODUCT_BLUEPRINT.TITLE_MENU, route: Screens.PRODUCT_BLUEPRINT_LIST }];
        if (Helpers.isNullOrEmpty(idDetail)) {
            temp.push({
                title: Strings.Common.CREATE_NEW,
                route: "",
            });
        } else {
            temp.push({
                title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE,
                route: "",
            });
        }
        return temp;
    }, [idDetail, mode]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.PRODUCT_BLUEPRINT_LIST);
        navigate(Screens.PRODUCT_BLUEPRINT_LIST + (itemPathName ? itemPathName.query : ""));
    };

    return (
        <DashboardLayout
            isPermission
            title={modeList ? Strings.PRODUCT_BLUEPRINT.TITLE_LIST : title}
            route={modeList ? [{ title: Strings.PRODUCT_BLUEPRINT.TITLE_MENU, route: "" }] : route}
        >
            <ModuleProductTemplate
                keyModuleContainer={{
                    list: { pathName: Screens.PRODUCT_BLUEPRINT_LIST },
                    detail: { pathName: Screens.PRODUCT_BLUEPRINT_EDIT },
                }}
                requestGetPaged={requestData}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ query, totalCount, pathname: Screens.PRODUCT_BLUEPRINT_LIST }));
                    navigate(Screens.PRODUCT_BLUEPRINT_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    const id = data.id ? `?id=${data.id}` : "";
                    navigate(Screens.PRODUCT_BLUEPRINT_EDIT + id, { state: { mode: data.mode } });
                }}
                mode={mode}
                idDetail={idDetail}
                onGoBack={() => {
                    handleGoBack();
                }}
                onChangeMode={(value) => {
                    setMode(value);
                }}
                templateType={TemplateType.BluePrint}
            />
        </DashboardLayout>
    );
};

export default ProductBlueprintScreen;
