import { ModuleCodename } from "@maysoft/common-component-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Screens from "constants/screens";
import Constants from "../../constants";

import useModule from "hooks/useModule";
import { DashboardLayout } from "layout";
import { PaymentAccountModule } from "@maysoft/sale-common-react";

const PaymentAccountScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        partnerCode: searchParams.get("partnerCode"),
        partnerName: searchParams.get("partnerName"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.LIMIT_SETTING_LIST,
            detail: Screens.LIMIT_SETTING_EDIT,
        },
        keyOfStrings: "LIMIT_SETTING",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <PaymentAccountModule {...moduleProps} />
        </DashboardLayout>
    );
};

export default PaymentAccountScreen;
