import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import AttributeService from "services/sale/attribute.service";

import { RoleHelpers } from "@maysoft/common-component-react";
import { tabArrLanguage } from "assets/data";
import { ICodename, ITitleRoute } from "commons/interfaces";
import { ActionRequest, AttributeType, AttributeValueType, IsTrue, Mode } from "constants/enum";
import { RootState } from "store";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setDataAlert } from "store/slice/message.slice";
import { IAttributeValue, IDataEditAttribute, IRecordAttribute } from "./interface";

interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
}

const useAttribute = (props?: { idDetail?: string; onCallbackData?: (data?: IRecordAttribute) => void }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const attributeService = new AttributeService();

    const userInfo = useSelector((state: RootState) => state.userInfoSlice);
    const listPathName = useSelector((state: RootState) => state.titleRoute?.listPathName);

    const currentLanguage = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);
    const isAdminService: boolean = useMemo(() => RoleHelpers.isServiceAdmin(userInfo?.userProfile?.roleType), [userInfo?.userProfile?.roleType]);

    const organizationList: ICodename[] = useMemo(() => {
        return [{ code: Constants.ORGANIZATION_ID_ADMIN_SERVICE, name: "Mặc định" }, ...(userInfo?.listOrganization || [])];
    }, [userInfo?.listOrganization]);

    const dataType: ICodename[] = [
        { code: `${AttributeType.Common}`, name: "Common" },
        { code: `${AttributeType.Product}`, name: Strings.PRODUCT.PRODUCT },
    ];

    const dataValueType: ICodename[] = [
        { code: `${AttributeValueType.Number}`, name: Strings.ATTRIBUTE.NUMBER },
        { code: `${AttributeValueType.String}`, name: Strings.ATTRIBUTE.STRING },
        { code: `${AttributeValueType.MultiLanguage}`, name: Strings.ATTRIBUTE.MULTI_LANGUAGE },
        { code: `${AttributeValueType.Upload}`, name: Strings.ATTRIBUTE.UPLOAD_FILES },
    ];

    const [isOnChaged, setIsOnChaged] = useState<boolean>(false);
    const [languages, setLanguages] = useState<string>(currentLanguage);

    const [model, setModel] = useState<IModel>({
        mode: Mode.Create,
        title: Strings.ATTRIBUTE.TITLE_CREATE_VIEW,
        route: [
            { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
            { title: Strings.Common.CREATE_NEW, route: "" },
        ],
    });
    const [dataDetailAttribute, setDataDetailAttribute] = useState<IDataEditAttribute>({
        title: { value: { [languages]: "" } },
        type: { value: `${AttributeType.Product}` },
        valueType: { value: dataValueType[0].code },
        organizationId: userInfo?.userProfile?.organizationId,
        attributeValues: [],
    });

    useEffect(() => {
        if (model.mode !== Mode.View) {
            let newListTemp = [...(dataDetailAttribute?.attributeValues || [])];

            if (newListTemp.length > 0) {
                let nameId = "";
                const newId = newListTemp[newListTemp.length - 1].id;

                switch (Number(dataDetailAttribute?.valueType?.value || dataValueType[0].code)) {
                    case AttributeValueType.MultiLanguage:
                        nameId = `myInputMultiLanguage_${newId}`;
                        break;
                    case AttributeValueType.Number:
                        nameId = `myInputNumber_${newId}`;
                        break;
                    case AttributeValueType.String:
                        nameId = `myInputString_${newId}`;
                        break;
                    default:
                        break;
                }

                var textbox = document.getElementById(nameId);
                if (textbox !== null) {
                    textbox.focus();
                    textbox.scrollIntoView();
                }
            }
        }
    }, [dataDetailAttribute?.attributeValues?.length]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find((el) => el.pathName === Screens.ATTRIBUTE_LIST);
        navigate(Screens.ATTRIBUTE_LIST + (itemPathName ? itemPathName.query : ""));
    };

    const onStart = async () => {
        try {
            dispatch(showLoading(true));

            const state = location.state as any;

            let mode: number = Mode.Create;
            let title: string = Strings.ATTRIBUTE.TITLE_CREATE_VIEW;
            let route = [
                { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" },
            ];

            let dataEdit: IDataEditAttribute = {
                title: { value: { [languages]: "" } },
                organizationId: userInfo?.userProfile?.organizationId,
                type: { value: `${AttributeType.Product}` },
                valueType: { value: dataValueType[0].code },
                attributeValues: [],
            };

            if (!Helpers.isNullOrEmpty(props?.idDetail)) {
                const result = await attributeService.getDetail(props?.idDetail);

                const attributeValues: IAttributeValue[] = [];
                [...(result?.attributeValues || [])].forEach((item: any) => {
                    attributeValues.push({
                        id: item.id,
                        value: item.value,
                        valueNumber: Number(item.valueType) === AttributeValueType.Number ? item.value.code : undefined,
                        valueString: Number(item.valueType) === AttributeValueType.String ? item.value.code : undefined,
                        action: ActionRequest.Update,
                        organizationId: { value: item.organizationId },
                        isPublic: item.organizationId === Constants.ORGANIZATION_ID_ADMIN_SERVICE ? IsTrue.True : IsTrue.False,
                    });
                });

                dataEdit = {
                    id: props?.idDetail,
                    code: { value: result.code },
                    type: { value: `${result.type}` },
                    organizationId: result.organizationId,
                    valueType: { value: `${result.valueType}` },
                    title: {
                        code: result.title?.code,
                        value: result.title?.value,
                    },
                    attributeValues: attributeValues,
                    updateTime: result.updateTime,
                };

                mode = isAdminService || result.organizationId === userInfo?.userProfile?.organizationId ? Mode.Update : Mode.View;

                if (!Helpers.isNullOrEmpty(state?.mode)) {
                    mode = state?.mode;
                }

                title = mode === Mode.View ? Strings.ATTRIBUTE.TITLE_DETAIL_VIEW : Strings.ATTRIBUTE.TITLE_UPDATE_VIEW;
                route = [
                    { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
                    { title: mode === Mode.View ? Strings.Common.DETAIL : Strings.Common.UPDATE, route: "" },
                ];
            }

            setModel({ ...model, mode, title, route });
            setDataDetailAttribute(dataEdit);
            setIsOnChaged(false);

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onChangeValueType = (newValue: any) => {
        const oldValue = dataDetailAttribute?.valueType?.value;

        let newListTemp = [...(dataDetailAttribute?.attributeValues || [])].map((item) => {
            let temp: any;
            switch (oldValue) {
                case `${AttributeValueType.Number}`:
                    temp = item.valueNumber;
                    break;
                case `${AttributeValueType.String}`:
                    temp = item.valueString;
                    break;
                case `${AttributeValueType.MultiLanguage}`:
                    temp = item.value?.value?.[languages];
                    break;
                default:
                    temp = "";
                    break;
            }
            switch (newValue) {
                case `${AttributeValueType.Number}`:
                    item.value = undefined;
                    item.valueString = undefined;
                    item.valueNumber = Helpers.isNumber(Number(temp)) ? temp : undefined;
                    break;
                case `${AttributeValueType.String}`:
                    item.valueNumber = undefined;
                    item.valueString = temp;
                    item.value = undefined;
                    break;
                case `${AttributeValueType.MultiLanguage}`:
                    item.value = Helpers.setValueMultiLanguage({ value: { [languages]: temp } }, languages, tabArrLanguage);
                    item.valueString = undefined;
                    item.valueNumber = undefined;
                    break;
                default:
                    break;
            }
            return item;
        });

        setIsOnChaged(true);
        setDataDetailAttribute({
            ...dataDetailAttribute,
            valueType: { value: newValue },
            attributeValues: newListTemp,
        });
    };

    const onValidateItemAttributeValue = (itemValue: IAttributeValue) => {
        let checked: boolean = true;
        let newdata: any = { ...itemValue };

        switch (Number(dataDetailAttribute?.valueType?.value)) {
            case AttributeValueType.MultiLanguage:
                if (Helpers.isNullOrEmpty(newdata.value?.value?.[languages])) {
                    newdata["value"] = { error: Strings.Validation.REQUIRED };
                    checked = false;
                }
                break;
            case AttributeValueType.Number:
                if (Helpers.isNullOrEmpty(newdata?.valueNumber)) {
                    newdata["value"] = { error: Strings.Validation.REQUIRED };
                    checked = false;
                }
                break;
            case AttributeValueType.String:
                if (Helpers.isNullOrEmpty(newdata?.valueString)) {
                    newdata["value"] = { error: Strings.Validation.REQUIRED };
                    checked = false;
                }
                break;
            default:
                break;
        }

        return { isChecked: checked, item: newdata as IAttributeValue };
    };

    const checkedValidated = () => {
        let checked: boolean = true;
        let data: any = { ...dataDetailAttribute };

        if (AttributeType.Common === Number(dataDetailAttribute?.type?.value)) {
            if (Helpers.isNullOrEmpty(data["code"]?.value)) {
                data["code"] = { error: Strings.Validation.REQUIRED };
                checked = false;
            }
        }
        if (Helpers.isNullOrEmpty(data["title"]?.value?.[languages])) {
            data["title"] = { error: Strings.Validation.REQUIRED };
            checked = false;
        }

        let isCheckedAttributeValue = true;
        let newListTemp = [...(data["attributeValues"] || [])].map((item) => {
            const dataCheck = onValidateItemAttributeValue(item);
            if (!dataCheck.isChecked) {
                isCheckedAttributeValue = false;
            }
            return dataCheck.item;
        });

        if (!isCheckedAttributeValue) {
            data["attributeValues"] = newListTemp;
            checked = false;
        }

        if (!checked) {
            setDataDetailAttribute(data);
        }

        return checked;
    };

    const onCreateOrUpdate = async () => {
        if (model.mode === Mode.View) {
            setModel({
                ...model,
                mode: Mode.Update,
                title: Strings.ATTRIBUTE.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.ATTRIBUTE.TITLE_MENU, route: Screens.ATTRIBUTE_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
        } else {
            const isCheckBasic = checkedValidated();
            if (isCheckBasic) {
                try {
                    dispatch(showLoading(true));

                    let attributeValues: any[] = [];
                    [...(dataDetailAttribute?.attributeValues || [])].forEach((item) => {
                        const valueMulti =
                            Number(dataDetailAttribute?.valueType?.value) === AttributeValueType.MultiLanguage
                                ? Helpers.setValueMultiLanguage(item.value, languages, tabArrLanguage)
                                : undefined;
                        attributeValues.push({
                            id: Number(item.action) === ActionRequest.Create ? undefined : item.id,
                            value: valueMulti,
                            valueString: Number(dataDetailAttribute?.valueType?.value) === AttributeValueType.String ? item.valueString : undefined,
                            valueNumber: Number(dataDetailAttribute?.valueType?.value) === AttributeValueType.Number ? item.valueNumber : undefined,
                            organizationId: item?.organizationId?.value || undefined,
                        });
                    });

                    let data: any = {
                        title: Helpers.setValueMultiLanguage(dataDetailAttribute?.title, languages, tabArrLanguage),
                        organizationId: dataDetailAttribute?.organizationId || undefined,
                        type: Number(dataType[1].code),
                        code: dataDetailAttribute?.code?.value || undefined,
                        valueType: Number(dataDetailAttribute?.valueType?.value),
                        updateTime: dataDetailAttribute?.updateTime,
                        attributeValues: attributeValues,
                    };

                    let result: any;
                    if (Helpers.isNullOrEmpty(dataDetailAttribute?.id)) {
                        result = await attributeService.create(data);
                    } else {
                        data = { ...data, id: dataDetailAttribute?.id };
                        result = await attributeService.update(data);
                    }

                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        const messageSuccess = Helpers.isNullOrEmpty(dataDetailAttribute?.id)
                            ? Strings.Message.CREATE_SUCCESS
                            : Strings.Message.UPDATE_SUCCESS;

                        props.onCallbackData && props.onCallbackData(result?.result);

                        dispatch(setDataAlert({ message: messageSuccess, type: "success" }));
                    }

                    dispatch(showLoading(false));
                } catch (error) {
                    dispatch(showLoading(false));
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            }
        }
    };

    //
    const handleAddNewItemAttributeValue = () => {
        let newId = Date.now().toString();
        let newListTemp = [...(dataDetailAttribute?.attributeValues || [])];

        if (newListTemp.length === 0) {
            if (AttributeType.Common === Number(dataDetailAttribute?.type?.value)) {
                newListTemp.push({
                    id: newId,
                    isPublic: isAdminService ? IsTrue.True : IsTrue.False,
                    organizationId: {
                        value: isAdminService ? Constants.ORGANIZATION_ID_ADMIN_SERVICE : userInfo?.userProfile?.organizationId,
                    },
                    action: ActionRequest.Create,
                });
            } else {
                newListTemp.push({
                    id: newId,
                    isPublic: IsTrue.False,
                    organizationId: { value: userInfo?.userProfile?.organizationId },
                    action: ActionRequest.Create,
                });
            }
        } else {
            let isChecked = true;
            newListTemp = newListTemp.map((item) => {
                const dataCheck = onValidateItemAttributeValue(item);
                if (!dataCheck.isChecked) {
                    isChecked = false;
                }
                return dataCheck.item;
            });

            if (isChecked) {
                if (AttributeType.Common === Number(dataDetailAttribute?.type?.value)) {
                    newListTemp.push({
                        id: newId,
                        isPublic: isAdminService ? IsTrue.True : IsTrue.False,
                        organizationId: {
                            value: isAdminService ? Constants.ORGANIZATION_ID_ADMIN_SERVICE : userInfo?.userProfile?.organizationId,
                        },
                        action: ActionRequest.Create,
                    });
                } else {
                    newListTemp.push({
                        id: newId,
                        isPublic: IsTrue.False,
                        organizationId: { value: userInfo?.userProfile?.organizationId },
                        action: ActionRequest.Create,
                    });
                }
            }
        }

        setIsOnChaged(true);
        setDataDetailAttribute({
            ...dataDetailAttribute,
            attributeValues: newListTemp,
        });
    };

    const handleDeleteItemAttributeValue = (row: IAttributeValue) => {
        const organizationIdCheck = isAdminService ? Constants.ORGANIZATION_ID_ADMIN_SERVICE : userInfo?.userProfile?.organizationId;
        if (!isAdminService && row.organizationId.value !== organizationIdCheck) {
            dispatch(setDataAlert({ message: Strings.Message.NO_ROLE_DELETE, type: "warning" }));
            return;
        } else {
            let data = [...(dataDetailAttribute?.attributeValues || [])];
            if (Helpers.isNullOrEmpty(row.action) || row.action === ActionRequest.Create) {
                data = data.filter((el) => el.id !== row.id);
            } else {
                // data[data.findIndex(el => el.id === row.id)].action = ActionRequest.Delete;
                data = data.filter((el) => el.id !== row.id);
            }

            setIsOnChaged(true);
            setDataDetailAttribute({ ...dataDetailAttribute, attributeValues: data });
        }
    };

    const handleOnchangeValueItem = (newValue: any, key: string, idItem: string) => {
        let attributeValues = [...(dataDetailAttribute?.attributeValues || [])];
        const index = attributeValues.findIndex((el) => el.id === idItem);

        if (index !== -1) {
            let itemTemp = attributeValues[index];
            switch (key) {
                case "value":
                    const valueTemp = Helpers.setValueMultiLanguage(
                        {
                            ...itemTemp?.value,
                            error: undefined,
                            value: {
                                ...itemTemp?.value?.value,
                                [languages]: newValue,
                            },
                        },
                        languages,
                        tabArrLanguage
                    );
                    itemTemp.value = valueTemp;
                    break;
                case "valueNumber":
                    itemTemp.valueNumber = newValue;
                    itemTemp.value = {
                        ...itemTemp?.value,
                        error: undefined,
                    };
                    break;
                case "valueString":
                    itemTemp.valueString = newValue;
                    itemTemp.value = {
                        ...itemTemp?.value,
                        error: undefined,
                    };
                    break;
                case "isPublic":
                    itemTemp.isPublic = newValue === true ? IsTrue.True : IsTrue.False;
                    itemTemp.organizationId = {
                        value: newValue ? Constants.ORGANIZATION_ID_ADMIN_SERVICE : userInfo?.userProfile?.organizationId,
                    };
                    break;
                case "organizationId":
                    itemTemp.organizationId = { value: newValue };
                    break;
                default:
                    break;
            }

            attributeValues[index] = itemTemp;

            setIsOnChaged(true);
            setDataDetailAttribute({
                ...dataDetailAttribute,
                attributeValues: attributeValues,
            });
        }
    };

    const onChangeDataDetail = (value: string, key: keyof IDataEditAttribute) => {
        if (isOnChaged === false) setIsOnChaged(true);
        if (key === "title") {
            setDataDetailAttribute((prev) => ({
                ...prev,
                [key]: {
                    ...prev.title,
                    value: {
                        ...prev.title?.value,
                        [languages]: value,
                    },
                },
            }));
            return;
        }
        setDataDetailAttribute((prev) => ({ ...prev, [key]: value }));
    };

    return {
        model,
        dataType,
        dataValueType,
        organizationList,
        isAdminService,

        dataDetailAttribute,
        setDataDetailAttribute,
        onChangeDataDetail,
        languages,
        setLanguages,

        isOnChaged,
        setIsOnChaged,

        //
        onStart,
        handleGoBack,
        onCreateOrUpdate,
        onChangeValueType,
        handleAddNewItemAttributeValue,
        handleDeleteItemAttributeValue,
        handleOnchangeValueItem,
    };
};

export default useAttribute;
