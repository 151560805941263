import colors from "assets/theme/base/colors";

const { gradients } = colors;

type Types = any;

const alert: Types = {
  styleOverrides: {
    root: {
      color: "#fff",
      alignItems: "center",
      "& .MuiAlert-action": {
        padding: "0 0 0 16px"
      }
    },
  },
};

export default alert;
