import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Screens from "constants/screens";
import Constants from "../../constants";

import { IRequestGetPagedTicketFund, TicketFundModule } from "@maysoft/sale-common-react";
import useModule from "hooks/useModule";
import { DashboardLayout } from "layout";
import moment from "moment";
import TicketFundService from "services/sale/ticketFund.service";

const ticketFundService = new TicketFundService();
const RequestDepositScreen = () => {
    const [searchParams] = useSearchParams();

    const [request] = useState<any>({
        startTime: searchParams.get("startTime"),
        endTime: searchParams.get("endTime"),
        confirmStatus: searchParams.get("confirmStatus"),
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),
    });

    const { moduleProps, title, route } = useModule({
        request,
        pathName: {
            list: Screens.REQUEST_DEPOSIT_LIST,
            detail: Screens.REQUEST_DEPOSIT_EDIT,
        },
        keyOfStrings: "REQUEST_DEPOSIT",
    });

    return (
        <DashboardLayout isPermission {...{ title, route }}>
            <TicketFundModule {...moduleProps} />
        </DashboardLayout>
    );
};

export default RequestDepositScreen;
